<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ title }}
                <small>{{ subTitle }}</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
                <div class="view_type">
                  <v-btn class="j_button type03" @click="getChartList">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-btn class="j_button type03" @click="getChartThumbs">
                    <v-icon>mdi-view-grid</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            class="chart"
            hide-actions
            :headers="headers[gridMode]"
            :items="chartItems"
            :search="search"
            @filtered="onFiltered"
            @sorted="onSorted"
          >
            <template #items="props">
              <tr v-if="modeList" 
                :active="props.selected" 
                @click="propStatus(props)"
                :style="getRowStyle(props.item)"
              >
                <td style="text-align: center">{{ props.item.no }}</td>
                <td style="text-align: center">{{ itemIdx(props.item) }}</td>
                <td v-if="!controllerMode && phaseAvailable">{{ props.item.PhaseName }}</td>
                <td v-if="!controllerMode && phaseAvailable">{{ props.item.GroupName }}</td>
                <td v-if="!controllerMode && (phaseAvailable || itemAvailable)">{{ props.item.ItemName }}</td>
                <td v-if="!controllerMode">{{ props.item.SubItemName }}</td>
                <td style="text-align: center">{{ props.item.ChartNo }}</td>
                <td style="text-align: center">{{ props.item.ChartType }}</td>
                <td>{{ props.item.Name }}</td>
                <td style="text-align: center">{{ props.item.Tags }}</td>
                <td style="text-align: center">{{ props.item.UsageStatus }}</td>
                <td>{{ props.item.MasterDesc }}</td>
              </tr>

              <tr v-else-if="modeThumbs" :active="props.selected">
                <td v-if="!controllerMode" style="text-align: center">{{ props.item.no }}</td>
                <td v-if="!controllerMode" v-html="direction(props.index)"></td>
                <td>
                  <button
                    v-for="chart in props.item.XmlString"
                    v-ripple flat large fab
                    :key="chart.ChartNo"
                    @click="onChartSvgClick(chart)"
                  >
                    <div style="display: flex; flex-direction: column; margin-left: 10px; margin-right: 10px;">
                      <div 
                        v-html="`
                          <span style='font-size: .8rem; ${chart.UsageStatus === 'N' ? 'color: red;' : ''}'>${chart.ChartNo}</span><br />
                          <span style='color: #000;${chart.UsageStatus === 'N' ? 'color: red;' : ''}'>${chart.Name}</span>
                        `"
                        style="margin-top: 10px;"
                      ></div>
                      <j-chart-svg style="cursor: pointer;" :height="65" :xmlString="chart.Svg" />
                    </div>
                  </button>
                </td>
              </tr>

            </template>
          </j-data-grid>
        </div>
      </div>

      <component 
        v-model="formOpened"
        :is="mFormComponent"
        :cat-code-init="catCodeInit"
        :key-value="keyValue"
        @close="onClose"
        @key-value-update="(v) => { keyValue = v} "
        @updated="onUpdated"
      />

    </div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { ChartLibraryService } from '@/services'
import JSysenvChartModals from '@/components/JSysenvChartModals'

export default {
  name: 'sysenv-chartlib',
  components: {
    ...JSysenvChartModals,
  },
  data: () => ({
    chartLibraryService: null,
    formOpened: false,
    mFormComponent: null,
    gridMode: 'Thumb', // 'Thumb', 'List'

    catCodeInit: '',
    keyValue: '',

    search: '',
    selected: [],
    headers: {
      Thumb: [],
      List: [],
    },
    chartItems: []
  }),
  computed: {
    getRowStyle() {
      return (item) => {
        return {
          color: item.UsageStatus === 'N' ? 'gray' : '',
          opacity: item.UsageStatus === 'N' ? '0.5' : '1'
        }
      }
    },
    codePropagated: {
      get() {  
        return this.$store.state.sysenv.codePropagated },
      set(val) { this.$store.commit('sysenv/codePropagate', val) }
    },
    modeThumbs: {
      get() { return this.gridMode == 'Thumb' },
      set() { this.gridMode = 'Thumb' }
    },
    modeList: {
      get() { return this.gridMode == 'List' },
      set() { this.gridMode = 'List' }
    },
    path2catCode() {
      let path2code_ = {
        '/sysenv/config/chartlib/donut'       : __C.CHART.CAT_CODE_DONUT,
        '/sysenv/config/chartlib/pie'         : __C.CHART.CAT_CODE_PIE,
        '/sysenv/config/chartlib/bar'         : __C.CHART.CAT_CODE_BAR_AXIS,
        '/sysenv/config/chartlib/barsolid'    : __C.CHART.CAT_CODE_BAR_SOLID,
        '/sysenv/config/chartlib/barprogs'    : __C.CHART.CAT_CODE_BAR_PROGRESS,
        '/sysenv/config/chartlib/bardelta'    : __C.CHART.CAT_CODE_BAR_DELTA,
        '/sysenv/config/chartlib/histogram'   : __C.CHART.CAT_CODE_HISTORY,
        '/sysenv/config/chartlib/radar'       : __C.CHART.CAT_CODE_RADAR,
        '/sysenv/config/chartlib/skyline'     : __C.CHART.CAT_CODE_SKYLINE,
        '/sysenv/config/chartlib/tsummary'    : __C.CHART.CAT_CODE_TABLE_SUMMARY,
        '/sysenv/config/chartlib/custom'      : __C.CHART.CAT_CODE_CUSTOM,
        '/sysenv/config/chartlib/tfloat'      : __C.CHART.CAT_CODE_FLOAT,
        '/sysenv/config/chartlib/summary'     : __C.CHART.CAT_CODE_SUMMARY,
        '/sysenv/config/chartlib/weekly'      : __C.CHART.CAT_CODE_WEEKLY,
        '/sysenv/config/page/svgpages'        : __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE,
        '/sysenv/config/complibsvg/chartcntl' : __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
        '/sysenv/config/complibsvg/svgnavs'   : __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION,
        '/sysenv/config/complibsvg/svgtabs'   : __C.CHART.CAT_CODE_COMPLIB_SVG_TAB,
      }
      return path2code_[this.$route.path]
    },
    phaseAvailable() { return this.codePropagated.parentCode == 'ALL' ? true : false },
    itemAvailable() { return this.codePropagated.code == 'ALL' ? true : false },
    controllerMode() { 
      return [
        __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
        __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION
      ].includes(this.path2catCode)
    },

    title() {
      // ### ATTENTION ###
      // Temporal process to fetch the chart data instead of the codePropagated,
      // because of that the Watcher for the codePropagated is not working on the
      // mode CONTROLLER.
      // This process will be moved to some right place later.
      if(this.controllerMode) this.onUpdated()
      // ---

      switch(this.path2catCode) {
        case __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER: var title_ = 'SVG Chart Controllers'; break;
        case __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION: title_ = 'SVG Navigations'; break;
        default: title_ = this.$store.state.sysenv.codePropagated.name
      }
      return title_
    },
    subTitle() { 
      switch(this.path2catCode) {
        case __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER: var title_ = "Predefined component's behaviors for the Page & Action"; break;
        case __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION: title_ = "With filtering the page conditions"; break;
        default: title_ = this.$store.state.sysenv.codePropagated.parentName
      }
      return title_
    }
  },
  watch: {
    codePropagated: {
      handler() { 
        this.onUpdated() },
      deep: true
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    itemIdx(item) {
      return item.NavIdx ? `${item.Idx} / ${item.NavIdx}` : item.Idx
    },
    direction(i) {
      if(!this.modeThumbs) return

      let direction_ = ''
      if(this.codePropagated.parentCode == 'ALL') direction_ = this.chartItems[i].PhaseName
      if(this.codePropagated.code == 'ALL') direction_ = direction_ + (direction_ ? ' /<br>' : '') + this.chartItems[i].ItemName
      direction_ = direction_ + (direction_ && this.chartItems[i].SubItemName ? ' /<br>' : '') + this.chartItems[i].SubItemName

      return direction_
    },

    setAdd() {
      this.onModalRequested({ catCode: this.path2catCode, chartNo: '' })
    },
    getChartList() {
      this.gridMode = 'List'
      this.chartItems = []
      // this.modeList = true
      this.setListHeader()

      this.chartLibraryService.getChartList(
        this.path2catCode,
        this.codePropagated.parentCode,
        this.codePropagated.code,
        (res) => {
          if(!res) res = []
          this.chartItems = res
        }
      )
    },
    getChartThumbs() {
      this.gridMode = 'Thumb'
      this.chartItems = []
      // this.modeThumbs = true
      this.setThumbHeader()
      
      this.chartLibraryService.getChartThumbs(
        this.path2catCode,
        this.codePropagated.parentCode || 'ALL',
        this.codePropagated.code || 'ALL',
        (res) => {
          if (!res) res = []
          this.chartItems = res
        }
      )
    },
    onChartSvgClick(chart) {
      this.onModalRequested({ catCode: this.path2catCode, chartNo: chart.ChartNo })
    },
    onClose() {
      // this.formOpened = false
      this.keyValue = null
      this.mFormComponent = null
    },
    onModalRequested(chartInfo) {
      this.mFormComponent = __C.CHART.CAT_CODE_COMPONENT[chartInfo.catCode] || null

      // Need little delay time to be done creating the component
      // before running.
      setTimeout(() => {
        this.catCodeInit = chartInfo.catCode
        this.keyValue = chartInfo.chartNo
        this.formOpened = true
      })
    },
    onFiltered(items) {
      let reNumbering = 0
      items.forEach(_item => {
        this.chartItems.find(
          _item_ => _item_.ChartNo == _item.ChartNo
        ).no = ++reNumbering
      })
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1
      })
    },
    onUpdated() {
      if (this.modeList) this.getChartList()
      else this.getChartThumbs()
    },
    propStatus(props) {
      this.onModalRequested({ catCode: this.path2catCode, chartNo: props.item.ChartNo })
    },
    setListHeader() {
      if(!this.modeList) return

      let headers_ = []

      if([
          __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
          __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION
        ].includes(this.path2catCode)) headers_ = [
        // 
      ]; else if(this.codePropagated.parentCode == 'ALL') headers_ = [
        { type: 'text', text: 'Phare Name', align: 'left', value: 'PhaseName', sortable: false, width: 150 },
        { type: 'text', text: 'Group Name', align: 'left', value: 'GroupName', sortable: false, width: 150 },
        { type: 'text', text: 'Item Name', align: 'left', value: 'ItemName', sortable: false, width: 200 },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'SubItemName', sortable: false, width: 120 },

      ]; else if(this.codePropagated.code == 'ALL') headers_ = [
        { type: 'text', text: 'Item Name', align: 'left', value: 'ItemName', sortable: false, width: 200 },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'SubItemName', sortable: false, width: 120 },
        
      ]

      headers_ = [ 
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50 },
        { type: 'text', text: 'IDX', align: 'center', value: 'Idx', sortable: false, width: 80 },
        ...headers_,
        { type: 'text', text: 'Code No.', align: 'center', value: 'ChartNo', sortable: false, width: 100 },
        { type: 'text', text: 'Type', align: 'center', value: 'ChartType', sortable: false, width: 80 },
        { type: 'text', text: 'Title', align: 'left', value: 'Name', sortable: false, width: 250 },
        { type: 'text', text: 'Tags', align: 'center', value: 'Tags', sortable: false, width: 100 },
        { type: 'text', text: 'UsageStatus', align: 'center', value: 'UsageStatus', sortable: false, width: 50 },
        { type: 'text', text: 'Desciption', align: 'left', value: 'Desc', sortable: false }
      ]      

      this.headers.List = headers_
    },
    setThumbHeader() {
      if(!this.modeThumbs) return

      let headers_ = []

      if(this.controllerMode) headers_ = [
        { type: 'text', text: 'SVG Items', align: 'left', value: 'JsonProps', sortable: false }
      ]; else headers_ = [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
        { type: 'text', text: 'Direction', align: 'left', value: '', sortable: false, width: 200, },
        { type: 'text', text: '', align: 'left', value: 'JsonProps', sortable: false },
      ]

      this.headers.Thumb = headers_
    },
    svg2data(xmlString) {
      if(!xmlString) return ''

      let blob = new Blob([xmlString], {type: 'image/svg+xml'});
      let url = URL.createObjectURL(blob);

      return url
    }
  },
}
</script>
